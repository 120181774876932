import React from "react";
import Layout from "../components/layout/Layout";
import Seo from "../components/seo";

export default function NotFound() {
  return (
    <Layout>
      <Seo title="ERROR 404" description="Sorry, that page doesn't exist" />
      <div>
        <h2>404</h2>
        <p>Sorry, that page doesn't exist</p>
      </div>
    </Layout>
  );
}
